@import "bootstrap/scss/bootstrap";

body {
  overflow-y: scroll;
}

.border-2em {
  border-radius: 2em;
}

.navbar {
  height: 4rem;
  min-height: 4rem;
  max-height: 4rem;
}

.btn:disabled {
  cursor: default !important;
}

table.layout-auto {
  table-layout: auto;
}

.table th {
  white-space: nowrap;
}

.table td, .table th {
  height: 2rem;
  min-height: 2rem;
  max-height: 2rem;
}


.root-table-sub-header-sticky {
  position: sticky;
  top: calc(4rem + 2rem + .75rem - 2px);
  z-index: 20;
}

.root-table-header-sticky {
  position: sticky;
  top: calc(4rem - 1px);
  z-index: 20;
}

.not-btn {
  cursor: default !important;
}

.not-btn:hover {
  cursor: default !important;
  background-color: transparent !important;
}

.fill-panel {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.scroll-y {
  overflow-y: auto !important;
}

.btn-outline-success.not-btn:hover,
.btn-outline-success.not-btn:active {
  border-color: var(--bs-success) !important;
  color: var(--bs-success) !important;
}

.btn-outline-warning.not-btn:hover,
.btn-outline-warning.not-btn:active {
  border-color: var(--bs-warning) !important;
  color: var(--bs-warning) !important;
}

.btn-outline-danger.not-btn:hover,
.btn-outline-danger.not-btn:active {
  border-color: var(--bs-danger) !important;
  color: var(--bs-danger) !important;
}

.btn-success.not-btn:hover,
.btn-success.not-btn:active {
  background-color: var(--bs-success) !important;
  color: var(--bs-white) !important;
}

.btn-warning.not-btn:hover,
.btn-warning.not-btn:active {
  background-color: var(--bs-warning) !important;
  color: var(--bs-white) !important;
}

.btn-danger.not-btn:hover,
.btn-danger.not-btn:active {
  background-color: var(--bs-danger) !important;
  color: var(--bs-white) !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-10 {
  z-index: 10 !important;
}

.z-100 {
  z-index: 100 !important;
}

.z-1000 {
  z-index: 1000 !important;
}

.col-narrow {
  width: 1em;
  white-space: nowrap;
}

.grow, .grow-lg {
  transition: all .02s ease-in-out;
}

.grow:hover {
  transform: scale(1.3);
}

.grow-lg:hover {
  transform: scale(2);
}

.icon-small .mdstaff-icon {
  height: 24px;
  width: 24px;
}

.mdstaff-icon {
  height: 34px;
  width: 34px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
}

input[type=date]::-webkit-clear-button {
  display: block;
}

.ng-dirty.ng-valid[required], .ng-dirty.ng-valid.required {
  border-color: var(--bs-success);
  box-shadow: rgba(var(--bs-success), .75);
}

.ng-dirty.ng-invalid:not(form) {
  border-color: var(--bs-danger);
  box-shadow: rgba(var(--bs-danger), .75);
}


.modal-content {
  animation: fade-in-modal .5s ease-out;
}

@keyframes fade-in-modal {
  0% {
    transform: translate3d(0, -30px, 0);
    opacity: 0;
  }
  25% {
    transform: translate3d(0, -30px, 0);
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    transform: translate3d(0, 0, 0);
    animation: fade-out-modal;
  }
}

@keyframes spin-up {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(360deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(720deg);
  }
}

mark {
  padding: 0 !important;
  background-color: var(--bs-yellow) !important;
}


/** Toastr Themes **/

.toast-error {
  background-color: var(--bs-danger);
}

.toast-warning {
  background-color: var(--bs-warning);
}

.toast-success {
  background-color: var(--bs-success);
}

.toast-info {
  background-color: var(--bs-info);
}


/** NG-SELECT theming Modifications **/
.ng-value {
  background-color: var(--bs-primary) !important;
  color: var(--bs-white);
}

.ng-option-selected {
  background-color: var(--bs-primary) !important;
  color: var(--bs-white) !important;
}

.ng-option-marked {
  background-color: var(--bs-primary) !important;
  color: var(--bs-white) !important;
}

.ng-option-selected .ng-option-label {
  color: var(--bs-white) !important;
}

.ng-value-icon {
  background-color: var(--bs-primary) !important;
}

/** Global overrides **/
input[type="time"]::-webkit-clear-button {
  display: none;
}

input[type="time"]::-ms-clear {
  display: none;
}

.pre {
  white-space: pre-wrap !important;
}

